import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import config from '../config';

const Collection: React.FC = () => {
  const { categoryId } = useParams();
  const [collection, setCollection] = React.useState<any | null>(null);

  React.useEffect(() => {
    const fetchLatestCollection = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/latest-collection/${categoryId}/`);
        setCollection(response.data);
      } catch (error) {
        console.error('Failed to fetch latest collection', error);
      }
    };

    fetchLatestCollection();
  }, [categoryId]);

  const handleDownload = async () => {
    try {
      const collectionId =collection.rundate ; // Replace with actual collection ID
      const catelogId = categoryId; // Replace with actual catelog ID
      const email = localStorage.getItem('email');
      
      const response = await axios.post(`${config.apiBaseUrl}/download-products/`, 
        { collection_id: collectionId, catelog_id: catelogId },
        { 
          headers: {
            'X-User-Email': email, // Add email to headers
          },
          responseType: 'blob' // Set responseType to blob for file download
        }
      );
      
      // Create a URL for the file and initiate download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      
    } catch (error) {
      console.error('Failed to download products CSV', error);
    }
  };
  

  return (
    <div>
      <h1>Latest Collection</h1>
      {collection ? (
        <div>
          <p><strong>Collection ID:</strong> {collection.collection_id}</p>
          <p><strong>Date:</strong> {new Date(collection.rundate).toLocaleDateString()}</p>
          <button onClick={handleDownload}>Download Products CSV</button>
        </div>
      ) : (
        <p>No collection found</p>
      )}
    </div>
  );
};

export default Collection;

import React from 'react';
import '../assets/css/modal.css'

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay" onClick={onClose}>
      <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
        <div className="custom-modal-header">
          <h5 className="custom-modal-title">{title}</h5>
          <button className="custom-modal-close" onClick={onClose}>×</button>
        </div>
        <div className="custom-modal-body">
          {children}
        </div>
        <div className="custom-modal-footer">
          <button className="custom-modal-close-footer" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;

import React, { useEffect, useRef, useState } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import config from "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home: React.FC = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dots, setDots] = useState<number[]>([]);
  const [cardsPerScreen, setCardsPerScreen] = useState(0);
  const [faqActiveIndex, setFaqActiveIndex] = useState<number | null>(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const handleResize = () => {
      const cards = slider.querySelectorAll<HTMLDivElement>(".feedback-card");
      const cardWidth = cards[0]?.offsetWidth || 0;
      const screenWidth = slider.offsetWidth;
      const totalCards = cards.length;
      const newCardsPerScreen = cardWidth > 0 ? Math.floor(screenWidth / cardWidth) : 0;
      const totalScreens = newCardsPerScreen > 0 ? Math.ceil(totalCards / newCardsPerScreen) : 0;

      setCardsPerScreen(newCardsPerScreen);
      setDots(Array.from({ length: totalScreens }, (_, i) => i));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const handleScroll = () => {
      const cardWidth = slider.querySelector<HTMLDivElement>(".feedback-card")?.offsetWidth || 0;
      if (cardWidth === 0 || cardsPerScreen === 0) return;

      const newCurrentIndex = Math.floor(slider.scrollLeft / (cardWidth * cardsPerScreen));
      setCurrentIndex(newCurrentIndex);
    };

    slider.addEventListener("scroll", handleScroll);

    return () => {
      slider.removeEventListener("scroll", handleScroll);
    };
  }, [cardsPerScreen]);

  const scrollToIndex = (index: number) => {
    const slider = sliderRef.current;
    if (!slider) return;

    const cardWidth = slider.querySelector<HTMLDivElement>(".feedback-card")?.offsetWidth || 0;
    if (cardWidth === 0 || cardsPerScreen === 0) return;

    slider.scrollTo({
      left: cardWidth * cardsPerScreen * index,
      behavior: "smooth",
    });
    setCurrentIndex(index);
  };

  const toggleFaq = (index: number) => {
    setFaqActiveIndex(faqActiveIndex === index ? null : index);
  };

  // Scroll to section based on URL hash
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hash]);


  const navigate_toregister=() =>{
    const email = localStorage.getItem('email');
    if (email){
      navigate('/dashboard/subscriptions')
    }else{

      navigate('/register')
    }
  }

  const handleemailSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(`${config.apiBaseUrl}/sample_email/`, { email });
      if (response.data.success) {
        toast.success('Sample list sent.');
      } else {
        setMessage(response.data.message || 'Something went wrong.');
      }
    } catch (error: any) {
      // Check if the error response exists from the backend
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || 'An unknown error occurred.';
        toast.error(`${errorMessage}`);
      } else {
        toast.error('An error occurred while sending the request.');
      }
    }
  };
  

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
      {/* Home Section */}
      <section className="home">
        <div className="home-content">
          <div className="text-content">
            <h1>Accurate Price Lists for Home Service Professionals</h1>
            <p>
              Enter your email and we will send you a sample pricelist along with a 20% discount code for your first purchase
            </p>
            <form className="email-form">
              <input type="email" placeholder="Enter your email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required />
              <button type="submit" onClick={handleemailSubmit}>Subscribe</button>
            </form>
          </div>
          <div className="image-content">
            <img src="/images/home-laptop.png" alt="Laptop" />
          </div>
        </div>
      </section>

      {/* Compatibility Section */}
      <section className="compatibility-section">
        <h2 className="compatibility-heading">Our PriceLists Are Compatible With</h2>
        <div className="compatibility-logos">
          <img src="/images/procore.png" alt="Procore" className="logo" />
          <img src="/images/housecallpro.png" alt="Housecall Pro" className="logo" />
          <img src="/images/quickbooks.png" alt="QuickBooks" className="logo" />
          <img src="/images/servicetrade.png" alt="ServiceTrade" className="logo" />
          <img src="/images/jobber.png" alt="Jobber" className="logo" />
          <img src="/images/workiz.png" alt="Workiz" className="logo" />
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section" id="feature">
        <h2 className="features-heading">FEATURES YOU NEED</h2>
        <p className="features-description">
          Up to date pricelists you can download quarterly and upload to your estimation and invoicing software. Have confidence that your products
          and materials are priced accurately and marked up exactly how much you want!
        </p>
        <div className="features-content">
          <img src="/images/features-latop.png" alt="Laptop with software" className="features-image" />
          <div className="features-details">
            <h3 className="features-subheading">YOUR ACCURATE CONTRACTOR PRICE MANAGER</h3>
            <ul className="features-list">
              <li><img src="/images/tick-with-circle.png" alt="tick" />Quarterly updates to ensure current pricing accuracy.</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />Batch Import our materials list into QuickBooks and other software programs</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />Consistent product lists and descriptions from each quarter edition</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />Enter your desired markup for various price ranges</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />You have the option to include sale tax, Inventory options, and income type</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Industries Section */}
      <section className="industries-section" >
        <h2 className="industries-heading">Industries we offer pricelists for</h2>
        <div className="industries-content">
          <div className="industry-item">
            <img src="/images/electrical.png" alt="Electrical" className="industry-image" />
            <p className="industry-text">Electrical</p>
          </div>
          <div className="industry-item">
            <img src="/images/landscape.png" alt="Landscape" className="industry-image" />
            <p className="industry-text">Landscape</p>
          </div>
          <div className="industry-item">
            <img src="/images/plumbing.png" alt="Plumbing" className="industry-image" />
            <p className="industry-text">Plumbing</p>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section className="video-section">
        <h2 className="video-heading">Your First Steps with <span className="brand-name">Accu</span><span className="brand-name2">Price</span></h2>
        <div className="video-placeholder">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/x91MPoITQ3I?si=D_nS47-K0xMmry2g"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <h1 className="benefits-title">Benefits</h1>
        <p className="benefits-subtitle">Discover the Advantages of AccuPrice</p>
        <div className="benefits-container">
          <div className="benefit-item">
            <img src="/images/benefit1.png" alt="Accurate Pricing" className="benefit-icon" />
            <h2 className="benefit-title">Accurate and Up-to-Date Pricing</h2>
            <p className="benefit-description">
              Our price lists are sourced directly from Home Depot, ensuring that your estimates and invoices reflect the latest market prices.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit2.png" alt="Ease of Use" className="benefit-icon" />
            <h2 className="benefit-title">Ease of Use</h2>
            <p className="benefit-description">
              Our user-friendly interface ensures that even those with limited technical skills can easily customize and utilize our price lists.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit3.png" alt="Comprehensive Coverage" className="benefit-icon" />
            <h2 className="benefit-title">Comprehensive Coverage</h2>
            <p className="benefit-description">
              Cover a wide range of materials and products commonly used in electrical, plumbing, HVAC, roofing, and other service industries.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit4.png" alt="Time-Saving" className="benefit-icon" />
            <h2 className="benefit-title">Time-Saving</h2>
            <p className="benefit-description">
              Reduce the time spent on price research and manual updates, allowing you to focus on more critical aspects of your business.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit5.png" alt="Improved Accuracy" className="benefit-icon" />
            <h2 className="benefit-title">Improved Accuracy in Estimates</h2>
            <p className="benefit-description">
              Generate accurate estimates and invoices, reducing errors and increasing client trust.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit6.png" alt="Customizable Markups" className="benefit-icon" />
            <h2 className="benefit-title">Customizable Profit Markups</h2>
            <p className="benefit-description">
              Easily configure profit markups for various price ranges to match your business strategy, allowing for precise and flexible pricing.
            </p>
          </div>
        </div>
      </section>

      {/* Price secrion */}
      <section className="price-section" id="pricing">
        <h2 className="price-title">Pricing plan</h2>
        <p className="price-subtitle">Choose the plan that fits your needs and start enjoying seamless service today.</p>
        <div className="price-section-wraper">
        <div className="price-cards-section">
            <div className="price-card price-card1">
              <div>
              <div className="price-dollar-icon">
                  <img className="dollar-icon" src="/images/dollar-icon.svg" alt="" />
              </div>
              <div className="price-amount-section">
                <h4 className="price-amount-title">Single Edition</h4>
                <h3 className="price-amount">$99</h3>
                <h4 className="price-amount-cut">Normally $150</h4>
              </div>
              <div className="price-hr" ></div>
              <div className="price-includes-section">
                <h4 className="price-includes-title">Includes:</h4>
                <div className="inlcude-items-section">

                <div className="inlucde-list-item">
                    <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                    <p className="inlcude-text">Feature text goes here</p>
                </div>
                <div className="inlucde-list-item">
                    <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                    <p className="inlcude-text">Feature text goes here</p>
                </div>
                <div className="inlucde-list-item">
                    <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                    <p className="inlcude-text">Feature text goes here</p>
                </div>

                </div>
              </div>
              </div>
              <div className="price-button">
                  <button className="price-button-move" onClick={navigate_toregister}>Get started</button>
              </div>
            </div>


            <div className="price-card price-card2">
              <div>
              <div className="price-dollar-icon">
                  <img className="dollar-icon" src="/images/dollar-icon.svg" alt="" />
              </div>
              <div className="price-amount-section">
                <h4 className="price-amount-title">Single Edition</h4>
                <h3 className="price-amount">$99</h3>
                <h4 className="price-amount-cut">Normally $150</h4>
              </div>
              <div className="price-hr" ></div>
              <div className="price-includes-section">
                <h4 className="price-includes-title">Includes:</h4>
                <div className="inlcude-items-section">

                <div className="inlucde-list-item">
                    <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                    <p className="inlcude-text">Feature text goes here</p>
                </div>
                <div className="inlucde-list-item">
                    <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                    <p className="inlcude-text">Feature text goes here</p>
                </div>
                <div className="inlucde-list-item">
                    <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                    <p className="inlcude-text">Feature text goes here</p>
                </div>

                </div>
              </div>
              </div>
              <div className="price-button">
                  <button className="price-button-move" onClick={navigate_toregister}>Get started</button>
              </div>
            </div>
        </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="accuprice-container">
        <h1 className="accuprice-title">EFFORTLESS PRICING WITH <span className="accuprice-highlight">ACCUPRICE</span></h1>
        <p className="accuprice-subtitle">Follow these simple steps to get started with AccuPrice</p>
        <div className="accuprice-steps">
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 1</div>
              <h2 className="step-title">Sign up</h2>
              <p className="step-description">Create an account to access our user portal.</p>
            </div>
          </div>
          <div className="step step-right">
            <div className="step-content">
              <div className="step-number">Step 2</div>
              <h2 className="step-title">Choose Your Price List</h2>
              <p className="step-description">Select the specific price list you need (electrical, plumbing, landscaping, etc.).</p>
            </div>
          </div>
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 3</div>
              <h2 className="step-title">Purchase or Subscribe</h2>
              <p className="step-description">
                Opt for a single edition purchase or subscribe for quarterly updates at a discounted rate.
              </p>
            </div>
          </div>
          <div className="step step-right">
            <div className="step-content">
              <div className="step-number">Step 4</div>
              <h2 className="step-title">Customize Your List</h2>
              <p className="step-description">
                Configure the price lists to include your specific profit markups and sales tax rates. You can set different markups for various price ranges to tailor the lists to your business needs.
              </p>
            </div>
          </div>
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 5</div>
              <h2 className="step-title">Download the Excel File</h2>
              <p className="step-description">Download the customized price list as an Excel file, ready for use.</p>
            </div>
          </div>
          <div className="step step-right">
            <div className="step-content">
              <div className="step-number">Step 6</div>
              <h2 className="step-title">Integrate with Your Software</h2>
              <p className="step-description">Import the price list into QuickBooks or other software programs to streamline your estimates and invoices.</p>
            </div>
          </div>
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 7</div>
              <h2 className="step-title">Generate Accurate Estimates and Invoices</h2>
              <p className="step-description">
                Use the updated price lists to produce accurate and professional estimates and invoices, enhancing your efficiency and credibility.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Customer Feedback Section */}
      <section className="customer-feedback-container">
        <h2 className="customer-feedback-title">Our Customer Feedback</h2>
        <p className="customer-feedback-subtitle">Don't take our word for it. Trust our customers</p>
        <div className="customer-feedback-slider" ref={sliderRef}>
          {/* Repeat feedback-card divs as necessary */}
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★☆☆</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">The time savings alone make AccuPrice invaluable. I no longer worry about outdated prices or manual errors.</p>
          </div>
          {/* Add more feedback cards as needed */}
        </div>
        <div className="feedback-pagination">
          {dots.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => scrollToIndex(index)}
            />
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <div className="faq-container">
        <h1 className="faq-heading">FAQs</h1>
        <p className="faq-subheading">Here are some frequently asked questions about AccuPrice</p>
        {[
          { question: "How do I sign up and access the price lists?", answer: "Simply create an account on our website to access the user portal. Once logged in, you can choose the specific price list you need (electrical, plumbing, landscaping, etc.) and either purchase a single edition or subscribe for quarterly updates." },
          { question: "Can I customize the price lists to include my own profit markups and sales tax rates?", answer: "Yes, our price lists are fully customizable. You can configure profit markups for various price ranges and set your sales tax rates to ensure the lists match your specific business needs." },
          { question: "How often are the price lists updated?", answer: "The price lists are updated quarterly, ensuring you always have access to the latest pricing data from Home Depot." },
          { question: "What formats are the price lists available in?", answer: "The price lists are available as downloadable Excel files, which can be easily integrated into software programs like QuickBooks." },
          { question: "How do I integrate the price lists into QuickBooks or other software?", answer: "After downloading the price list, you can import the Excel file into QuickBooks or other compatible software. We provide step-by-step guides and how-to videos to assist with this process." },
          { question: "What if the products or materials I need are not listed?", answer: "Our price lists cover a wide range of general items from Home Depo. If specific items are not listed, please contact our support team, and we will do our best to accommodate your needs in future updates." },
          { question: "How secure is my data on your platform?", answer: "We prioritize data security and employ industry-standard measures to protect your information. Your data is securely stored and never shared with third parties." },
          { question: "Are there any additional costs besides the subscription or purchase price?", answer: "No, there are no hidden fees. The subscription or purchase price covers all costs, including updates and customer support. These FAQs address common concerns and provide clear, helpful information to potential users, enhancing their understanding and confidence in your product." },
          { question: "How do I know which price list is right for my business?", answer: "Each price list is tailored for specific industries, such as electrical, plumbing, and landscaping. Review the descriptions of each list to determine which one aligns with your business needs. Our support team is also available to help you choose the best option." },
          { question: "How often do I need to update the price lists in my software?", answer: "We recommend updating your price lists every quarter when new editions are released to ensure you have the most current pricing information." },
          { question: "What if I need to cancel my subscription?", answer: "You can cancel your subscription at any time through your user portal. If you cancel, you will still have access to the price lists for the remainder of the subscription period." },
          { question: "How long does it take to set up and start using the price lists?", answer: "The setup process is quick and straightforward. Once you sign up, you can access and download your price lists immediately. Customization and integration into your software should only take a few minutes with our guides and support." },
        ].map((faq, index) => (
          <div className="faq-item" key={index}>
            <button className={`faq-question ${faqActiveIndex === index ? "active" : ""}`} onClick={() => toggleFaq(index)}>
              {faq.question}
            </button>
            <div className="faq-answer" style={{ maxHeight: faqActiveIndex === index ? "none" : 0 }}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Signup Footer Section */}
      <section className="signup-footer">
        <h1>Join AccuPrice Now!</h1>
        <p>Enter your email and we will send you a sample pricelist along with a 20% discount code for your first purchase</p>
        <form className="signup-form">
          <input type="email" placeholder="Enter your email"
          value={email} 
          onChange={(e) => setEmail(e.target.value)}   required />
          <button type="submit" onClick={handleemailSubmit}>Sign Up</button>
        </form>
        <p className="terms-conditions">
          By clicking Sign Up you’re confirming that you agree with our <a href="">Terms and Conditions</a>.
        </p>
      </section>
    </div>
  );
};

export default Home;

import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import config from '../config';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../src/assets/css/register.css';

type ErrorResponse = {
  [key: string]: string[];
};

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrors([]);

    if (password !== repeatPassword) {
      setErrors(['Passwords do not match!']);
      toast.error('Passwords do not match!')
      return;
    }

    try {
      const response = await axios.post(`${config.apiBaseUrl}/register/`, {
        username: name,
        email: email,
        password: password,
        password2: repeatPassword,
      });
      console.log('User registered:', response.data);
      toast.success('User Registered successfull.');
      setTimeout(() => navigate('/login'), 5000);
      // navigate('/login'); 
      // alert('User was created successfully');
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      console.error('There was an error registering the user!', error);
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessages: string[] = [];

        for (const key in responseData) {
          if (responseData[key]) {
            errorMessages.push(`${key}: ${responseData[key][0]}`);
            toast.error(responseData[key][0]);
          }
        }

        if (errorMessages.length === 0) {
          errorMessages.push('There was an error registering the user!');
          
        }

        setErrors(errorMessages);
      } else {
        setErrors(['There was an error registering the user!']);
      }
    }
  };

  const handleGoogleSuccess = async (response: CredentialResponse) => {
    if (response.credential) {
      try {
        const googleResponse = await axios.post(`${config.apiBaseUrl}/google-login/`, {
          token: response.credential,
        });

        if (googleResponse.status === 200) {
          localStorage.setItem('userSession', JSON.stringify(googleResponse.data));
          localStorage.setItem('email', googleResponse.data.email);
          localStorage.setItem('username', googleResponse.data.name);
          navigate('/dashboard');
        } else {
          alert('Google Login failed');
        }
      } catch (err) {
        console.error('There was an error logging in with Google!', err);
        setErrors(['There was an error logging in with Google!']);
      }
    }
  };

  const handleGoogleFailure = () => {
    console.error('Google OAuth Failed'); // Log the failure
    setErrors(['Google login failed.']);
  };

// ==========================================================================
  

return (
    <div className="signup-container">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
      <div className="signup-main-modal">
        <div className="modal-header">
          <Link to="/" className="close-btn"><img src="/images2/cross.png" alt="Close" /></Link>
        </div>
        <div className="signup-modal">
          <img src="/images2/Accu-logo.png" alt="Accuprice Logo" />
          <h3>Join us on Accuprice</h3>
          <p>Sign up to see more</p>
          <form className='register-form' onSubmit={handleRegister}>
            <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
            
            <input  type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email here..." />

            {/* {errors.length > 0 && (
              <div className="error-messages">
                {errors.map((error, index) => (
                  <div style={{color: 'red'}} key={index} className="error-message">{error}</div>
                ))}
              </div>
            )} */}

            <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            <input type="password" name="password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} placeholder="Password" />

            <button type="submit">Sign up</button>
          </form>
          <div className="social-signup">
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
            />
          </div>
          <p className="terms">
            By continuing, you agree to the <a href="#">Terms of Service</a><br />
            and acknowledge you've read our <a href="#">Privacy Policy</a>.
          </p>
        </div>
        <p className="login-link">Already a member? <Link to="/login">Log in</Link></p>
      </div>
    </div>
  );
};

export default Register;

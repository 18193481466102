import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../Usercontext'; // Adjust the path as needed

const DashboardHeader: React.FC = () => {
  const { userEmail, userName, avatarUrl } = useUser();
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleResize = () => {
      const isSidebarCollapsed = document.body.classList.contains('sidebar-collapsed');
      setIsCollapsed(isSidebarCollapsed);
    };

    handleResize(); // Check initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="header">
      <div className="profile">
        <img src={avatarUrl} alt="Profile" />
        <div className="profile-info">
          <h2>{userName || 'User Name'}</h2>
          <p>{userEmail || 'No email found'}</p>
        </div>
      </div>
      <div className="dash-header-logo">
        <Link to="/">
          <img src="/images/logo.png" alt="Accuprice Logo" style={{ width: '140px' }} />
        </Link>
      </div>
      <div className="header-dots">
        <Link to="user-guide">
          <img src="/images/dash-3dots.png" alt="" />
        </Link>
      </div>
      <div className="cart" style={{ marginRight: isCollapsed ? '150px' : '' }}>
        <img src="/images2/cart.svg" alt="Cart" />
      </div>
    </header>
  );
};

export default DashboardHeader;

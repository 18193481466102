import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;

    if (path.endsWith('/success')) {
      // Handle successful payment
      // alert('Payment successful!');
      localStorage.setItem('status','true');
      localStorage.setItem('status2','true');
      navigate('/dashboard/subscriptions');
    } else if (path.endsWith('/cancel')) {
      // Handle canceled payment
      // alert('Payment canceled!');
      navigate('/dashboard/subscriptions');
    }
  }, [location.pathname, navigate]);

  return <div></div>;
};

export default RedirectHandler;

import React from 'react';


const UserGuide: React.FC = () => {
  return (
    <section id="user-guide" className="content-section">
      <div className="user-guide-section">
        <h1 className="user-guide-title">Welcome to Accuprice!</h1>
        <p className="user-guide-description">
          Watch the video below to get a quick overview of how to use our website and make the most of its features.
        </p>
        <div className="user-guide-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/x91MPoITQ3I?si=D_nS47-K0xMmry2g"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
        <div className="user-guide-help">
          <div className="help-icon">❓</div>
          <div className="help-content">
            <h2 className="help-title">Need More Help?</h2>
            <p className="help-description">
              If you have any questions or need further assistance, feel free to reach out to our support team at{' '}
              <a href="mailto:accuprice@help.com">accuprice@help.com</a> or visit our Help Center.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserGuide;

import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

import config from '../../config'; // Adjust the import according to your project structure
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../../Usercontext'; // Adjust the path as needed
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utility function to convert hyphenated strings to camel case
const toCamelCase = (str: string) => {
  return str
    .split('-')
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

type ErrorResponse = {
  [key: string]: string[];
};

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('general-settings');
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const [fileInputKey, setFileInputKey] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState<boolean>(false); 
  const [email, setEmail] = useState<string>(''); 
  const [status_sub,setStatussub] = useState<boolean>(false)
  const [status_ver,setStatusver] = useState<boolean>(false)
  const [update_edition,setUpdate_edition] = useState<boolean>(false)
  const [update_app,setUpdate_app] = useState<boolean>(false)

  const navigate = useNavigate();

  const { avatarUrl, setAvatarUrl, userEmail, setUserEmail, userName, setUserName } = useUser();

  useEffect(() => {
    const userSession = localStorage.getItem('userSession');
    const savedEmail = localStorage.getItem('email');
    const status_subs = localStorage.getItem('verified');
    const update1 = localStorage.getItem('update1');
    const update2 = localStorage.getItem('update2');
    if (status_subs == 'true')
      setStatusver(true)
    else
      setStatusver(false)
    
    if (update1 == 'true')
      setUpdate_edition(true)
    else
      setUpdate_edition(false)
    
    if (update2 == 'true')
      setUpdate_app(true)
    else
      setUpdate_app(false)

    if (userSession) {
      const userData = JSON.parse(userSession);
      setUserName(userData.name || '');
      setUserEmail(savedEmail || '');
      setUserId(userData.id || null);
      if (userData.id) {
        fetchProfilePicture(userData.id);
      }
      setEmail(savedEmail || ''); 
    }
  }, [setUserName, setUserEmail]);

  const fetchProfilePicture = async (userId: number) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/users/${userId}/profile-picture/`);
      const { profile_picture_url } = response.data;
      const serverUrl = `${config.imageBaseUrl}`;
      const finalAvatarUrl = profile_picture_url ? `${serverUrl}${profile_picture_url}` : '/images2/profile-pic.png';
      setAvatarUrl(finalAvatarUrl);
      localStorage.setItem('avatarUrl', finalAvatarUrl);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
      toast.error('Failed to fetch profile picture.');
      setAvatarUrl('/images2/profile-pic.png');
      localStorage.removeItem('avatarUrl');
    }
  };

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    setShowTabs(false);
  };

  const toggleTabs = () => {
    setShowTabs(!showTabs);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result && typeof event.target.result === 'string') {
          setAvatarUrl(event.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!selectedFile || userId === null) return;

    try {
      const formData = new FormData();
      formData.append('profile_image', selectedFile);

      await axios.post(`${config.apiBaseUrl}/users/${userId}/update-profile/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      fetchProfilePicture(userId);
      toast.success('Profile picture updated successfully.');
    } catch (error) {
      console.error('Error updating profile picture:', error);
      toast.error('Failed to update profile picture.');
    }
  };

  const handleRemoveImage = async () => {
    if (userId === null) return;

    try {
      await axios.delete(`${config.apiBaseUrl}/users/${userId}/delete-profile-picture/`);
      setAvatarUrl('/images2/profile-pic.png'); 
      setSelectedFile(null);
      localStorage.setItem('avatarUrl', '/images2/profile-pic.png');
      toast.success('Profile picture removed successfully.');
    } catch (error) {
      console.error('Error deleting profile picture:', error);
      toast.error('Failed to remove profile picture.');
    }
  };

  const handleSaveSettings = async () => {
    try {
      const response = await axios.put(`${config.apiBaseUrl}/users/${userId}/update/`, {
        name: userName,
        email: userEmail,
        update2:update_app,
        update1:update_edition
      });

      const userSession = localStorage.getItem('userSession');
      const { is_active, success } = response.data;
      if (userSession) {
        const userData = JSON.parse(userSession);
        userData.name = userName;
        userData.email = userEmail;
        localStorage.setItem('userSession', JSON.stringify(userData));
        localStorage.setItem('update1', update_edition.toString());
        localStorage.setItem('update2', update_app.toString());
      }
      
      toast.success(success);

      if (!is_active) {
        const response = await axios.get(`${config.apiBaseUrl}/logout/`);
      localStorage.removeItem('userSession'); 
      localStorage.removeItem('email'); 
      localStorage.removeItem('activeSection')
      localStorage.removeItem('avatarUrl')
      localStorage.removeItem('status')
      localStorage.removeItem('status2')
      localStorage.removeItem('update1')
      localStorage.removeItem('update2')
      localStorage.removeItem('username')
      localStorage.removeItem('verified')
        // setIsLoggedIn(false);
        toast.info('You need to activate your account. Please check your email.');
        setTimeout(() => navigate('/'), 5000);
        // navigate('/'); // Redirect to home page or login page
          
        }


    } catch (error) {
      console.error('Error updating user settings:', error);
      const err = error as AxiosError<ErrorResponse>;
      if (err.response && err.response.data) {
        const responseData = err.response.data;
        const errorMessages: string[] = [];

        for (const key in responseData) {
          if (responseData[key]) {
            errorMessages.push(`${key}: ${responseData[key]}`);
            toast.error(responseData[key]);
          }
        }

        if (errorMessages.length === 0) {
          errorMessages.push('There was an error logging in!');
        }

        
      } 







      toast.error('Failed to update settings.');
    }
  };
  const handleDelete = async () => {
    try {
      // Perform the delete request
      await axios.delete(`${config.apiBaseUrl}/users/${userId}/delete/`);
      
      // Clear local storage
      localStorage.removeItem('userSession');
      localStorage.removeItem('update1');
      localStorage.removeItem('update2');
      localStorage.removeItem('avatarUrl');
      localStorage.removeItem('email');
      localStorage.removeItem('status');
      localStorage.removeItem('status2');
      localStorage.removeItem('verified');
  
      // Optionally, you may want to redirect the user or update the state
      // e.g., redirect to a different page or update the app state
  
      toast.success('User deleted successfully.');
      setTimeout(() => navigate('/login'), 5000);
      // navigate('/login')
    } catch (error) {
      console.error('Error deleting user:', error);
      
      toast.error('Failed to delete user.');
    }
  };
  

  const handleResetPassword = async () => {
    try {
      await axios.post(`${config.apiBaseUrl}/request-password-reset/`, { email });
      setShowResetPasswordForm(false);
      toast.success('Password reset link sent to your email.');
    } catch (error) {
      console.error('Failed to send reset email', error);
      toast.error('Failed to send reset email.');
    }
  };


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === 'priceapp_update') {
      setUpdate_app(checked);
      localStorage.setItem('update2', checked.toString());
    } else if (name === 'new_edition_update') {
      setUpdate_edition(checked);
      localStorage.setItem('update1', checked.toString());
    }
  };

  return (
    <section id="settings" className="content-section">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
      <div className="settings-header">
        <h1 className="settings-title" onClick={toggleTabs}>
          {showTabs ? 'Settings' : toCamelCase(activeTab)}
        </h1>
      </div>
      {showTabs && (
        <div className="tab-buttons">
          <button
            className={`tab-button ${activeTab === 'general-settings' ? 'active' : ''}`}
            onClick={() => handleTabClick('general-settings')}
          >
            General Settings
          </button>
          <button
            className={`tab-button ${activeTab === 'connections' ? 'active' : ''}`}
            onClick={() => handleTabClick('connections')}
          >
            Connections
          </button>
          <button
            className={`tab-button ${activeTab === 'updates' ? 'active' : ''}`}
            onClick={() => handleTabClick('updates')}
          >
            Updates
          </button>
          <button
            className={`tab-button ${activeTab === 'security' ? 'active' : ''}`}
            onClick={() => handleTabClick('security')}
          >
            Security
          </button>
          <button
            className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
            onClick={() => handleTabClick('profile')}
          >
            Profile
          </button>
        </div>
      )}
      <div className="settings-all-data">
        <div className={`settings-left-data ${activeTab === 'profile' ? 'hidden' : ''}`}>
          <div className={`tab-content ${activeTab === 'general-settings' ? 'active' : ''}`} id="general-settings">
            <div className="setting-container">
              <h1>Settings</h1>
              <div className="form-area-line">
                <hr />
              </div>
              <div className="main-form-group">
                <div className="form-group">
                  <label htmlFor="first-name">First name</label>
                  <input 
                    type="text" 
                    id="first-name" 
                    value={userName || ''} 
                    onChange={(e) => setUserName(e.target.value)} 
                  />
                </div>
                <div className="form-group">
                  <label style={{ display: 'none' }} htmlFor="last-name">Last name</label>
                  <input 
                    style={{ display: 'none' }} 
                    type="text" 
                    id="last-name" 
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <div className="email-group">
                    <img src="/images2/email-ico.svg" alt="Email Icon" />
                    <input 
                      type="email" 
                      id="email" 
                      value={userEmail || ''} 
                      onChange={(e) => setUserEmail(e.target.value)} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`tab-content ${activeTab === 'connections' ? 'active' : ''}`} id="connections">
            <div className="connection-container">
              <h1>Connections</h1>
              <div className="form-area-line">
                <hr />
              </div>
              <div className="linkgroup">
                <div className="input-group">
                  <div className="icon google-icon"></div>
                  {status_ver ? (
                        <input type="text" value={userEmail || ''} readOnly />
                      ) : (
                        <input type="text" value="" readOnly />
                      )}
                  <button className="link-button">
                    <img src="/images2/Link.svg" alt="Link" />
                  </button>
                </div>
                {/* <button className="delete-button">
                  <img src="/images2/TrashCan.svg" alt="Delete" />
                </button> */}
              </div>
            </div>
          </div>
          <div className={`tab-content ${activeTab === 'updates' ? 'active' : ''}`} id="updates">
            <div className="updates-container">
              <div className="updates">
                <h2>Updates</h2>
                <div className="form-area-line" style={{ marginBottom: '5px' }}>
                  <hr />
                </div>
                <label>
                  <input type="checkbox" name='priceapp_update'
                   checked={update_app}
                    onChange={handleCheckboxChange} />
                  Receive updates on Accuprice on email
                </label>
                <label>
                  <input type="checkbox" name='new_edition_update'  checked={update_edition}
          onChange={handleCheckboxChange} />
                  Receive updates on new edition release on email
                </label>
              </div>
            </div>
          </div>
          <div className={`tab-content ${activeTab === 'security' ? 'active' : ''}`} id="security">
            <h1>Security</h1>
            <div className="actions">
              <div className="account-actions">
                <div className="form-area-line security-line">
                  <hr />
                </div>
                <h3 className='security-line'>Password</h3>
                {showResetPasswordForm ? (
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleResetPassword();
                  }}>
                    <input type="hidden" value={email} />
                    <button style={{ padding: '0', fontSize: '15px' }} type="submit">Send Reset Link</button>
                  </form>
                ) : (
                  <Link to='' onClick={() => setShowResetPasswordForm(true)}>Reset password</Link>
                )}
                <div className="form-area-line security-line">
                  <hr />
                </div>
                <h3 className='security-line'>Account</h3>
                <button className='action-button' onClick={handleDelete}>Delete Account</button>
                <div className="form-area-line security-line">
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`tab-content profile-content ${activeTab === 'profile' ? 'active' : ''}`} id="profile">
          <div className="set-2">
            <div className="profile-pic">
              <div className="avatar">
                <img src={avatarUrl || '/images2/profile-pic.png'} alt="Profile" />
                <img className="pic-add-button" onClick={() => document.getElementById('file-input')?.click()} src="/images2/picture-add-button.png" alt="" />
              </div>
              <input
                type="file"
                id="file-input"
                key={fileInputKey}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <button className="upload" onClick={handleSave}>
                Upload
              </button>
              <button className="remove" onClick={handleRemoveImage}>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-butons">
        <button className="cancel">Cancel</button>
        <button className="save" onClick={handleSaveSettings}>Save</button>
      </div>
      <style>
        {`
        .hidden {
          display: none;
        }
        .profile-content.active {
          margin: auto;
        }
        `}
      </style>
    </section>
  );
};

export default Settings;

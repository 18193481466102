import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import axios from 'axios';
import config from './config'; // Adjust the import according to your project structure

interface UserContextProps {
  avatarUrl: string;
  setAvatarUrl: (url: string) => void;
  userEmail: string | null;
  setUserEmail: (email: string | null) => void;
  userName: string | null;
  setUserName: (name: string | null) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [avatarUrl, setAvatarUrl] = useState<string>('/images2/profile-pic.png');
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const userSession = localStorage.getItem('userSession');
      if (userSession) {
        const userData = JSON.parse(userSession);
        setUserName(userData.name || '');
        setUserEmail(userData.email || '');
        if (userData.id) {
          fetchProfilePicture(userData.id);
        }
      }
    };

    const fetchProfilePicture = async (userId: number) => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/users/${userId}/profile-picture/`);
        const { profile_picture_url } = response.data;
        const serverUrl = `${config.imageBaseUrl}`;
        const finalAvatarUrl = profile_picture_url ? `${serverUrl}${profile_picture_url}` : '/images2/profile-pic.png';
        setAvatarUrl(finalAvatarUrl);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
        setAvatarUrl('/images2/profile-pic.png');
      }
    };

    fetchData();
  }, []);

  return (
    <UserContext.Provider value={{ avatarUrl, setAvatarUrl, userEmail, setUserEmail, userName, setUserName }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

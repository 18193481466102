import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'


const Protect = () => {

    const auth =localStorage.getItem("email")
  return auth ? <Outlet/> : <Navigate  to="/login" />
}

export default Protect
import React from 'react';
import { Link } from 'react-router-dom';


const Verified: React.FC = () => {
    return (
        <div className="signup-container">
            <div className="signup-main-modal">
                <div className="signup-modal">
                    <img src="/images2/Accu-logo.png" alt="Accuprice Logo" />
                    <h3>Great! You're now verified</h3>
                    <div className='continue-button-div'>
                    <Link className='continue-button' to='/'>Continue to Accu Price</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Verified;

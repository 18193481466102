import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';

interface BlogPost {
  id: number;
  title: string;
  intro: string;
  category: string;
  author: string;
  aurther_img: string;  // Typo corrected to match actual property
  blog_thumbnail: string;  
  created_at: string;
}

const BlogPage: React.FC = () => {
  // const [selectedCategory, setSelectedCategory] = useState('View all');
  const [blogs, setBlogs] = useState<BlogPost[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Fetch all blogs from the API
        const response = await axios.get(`${config.apiBaseUrl}/all_blogs/`);
        setBlogs(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Failed to fetch blogs', error);
      }
    };

    fetchBlogs();
  }, []);

  
  // const handleCategoryClick = (category: string) => {
  //   setSelectedCategory(category);
  // };

  // const filteredBlogPosts = selectedCategory === 'View all'
  //   ? blogPosts
  //   : blogPosts.filter(post => post.category === selectedCategory);

  return (
    <div>
      <div className="all-blogs-container">
        <div className="hero-section">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <div className="tag-line">
            <p>Accurate Price Lists for Home Service Professionals</p>
          </div>
        </div>
        <div className="blog-list">
          {/* <div className="options">
            <a href="#" className={selectedCategory === 'View all' ? 'option-active' : ''} onClick={() => handleCategoryClick('View all')}>View all</a>
            <a href="#" className={selectedCategory === 'Category One' ? 'option-active' : ''} onClick={() => handleCategoryClick('Category One')}>Category One</a>
            <a href="#" className={selectedCategory === 'Category Two' ? 'option-active' : ''} onClick={() => handleCategoryClick('Category Two')}>Category Two</a>
          </div> */}
          <div className="blog-posts">
          {blogs.map(post => (
            <Link key={post.id} className="blog-posts-post" to={`/blog/${post.id}`}>
              <div className="post">
                <img src={`${config.imageBaseUrl}${post.blog_thumbnail}`} alt={post.title} />
                <div className="post-data">
                  <div className="post-data-data">
                    <div className="category">{post.category}</div>
                    <div className="title">{post.title}</div>
                    <div className="para">
                      <p>{post.intro}</p>
                    </div>
                    <div className="author">
                      <img className='blog-auther-img' src={`${config.imageBaseUrl}${post.aurther_img}`} alt="Author" />
                      <div className="post-date">
                        <h6>{post.author}</h6>
                        <p>
                          <span>{new Date(post.created_at).toLocaleDateString()}</span>
                          {/* <span> <img src="/images2/dot.svg" alt="Dot" /></span> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config'; 

const Navbar: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const session = localStorage.getItem('userSession');
    setIsLoggedIn(!!session);

    const handleMenuIconClick = () => {
      const menuIcon = document.getElementById('menu-icon');
      const mobileMenu = document.getElementById('mobile-menu');
      
      if (menuIcon && mobileMenu) {
        menuIcon.classList.toggle('active');
        mobileMenu.classList.toggle('active');
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const menuIcon = document.getElementById('menu-icon');
      const mobileMenu = document.getElementById('mobile-menu');
      
      if (menuIcon && mobileMenu && !menuIcon.contains(event.target as Node) && !mobileMenu.contains(event.target as Node)) {
        mobileMenu.classList.remove('active');
        menuIcon.classList.remove('active');
      }
    };

    const collapseMenu = () => {
      const menuIcon = document.getElementById('menu-icon');
      const mobileMenu = document.getElementById('mobile-menu');
      
      if (menuIcon && mobileMenu) {
        mobileMenu.classList.remove('active');
        menuIcon.classList.remove('active');
      }
    };

    const menuIcon = document.getElementById('menu-icon');
    if (menuIcon) {
      menuIcon.addEventListener('click', handleMenuIconClick);
    }
    document.addEventListener('click', handleClickOutside);

    const mobileLinks = document.querySelectorAll('.mobile-menu .mobile-links a');
    mobileLinks.forEach(link => {
      link.addEventListener('click', collapseMenu);
    });

    const mobileButtons = document.querySelectorAll('.mobile-menu .mobile-buttons a');
    mobileButtons.forEach(button => {
      button.addEventListener('click', collapseMenu);
    });

    return () => {
      if (menuIcon) {
        menuIcon.removeEventListener('click', handleMenuIconClick);
      }
      document.removeEventListener('click', handleClickOutside);

      mobileLinks.forEach(link => {
        link.removeEventListener('click', collapseMenu);
      });
      mobileButtons.forEach(button => {
        button.removeEventListener('click', collapseMenu);
      });
    };
  }, []);



  const handleLogout = async () => {
    const response = await axios.get(`${config.apiBaseUrl}/logout/`);
      localStorage.removeItem('userSession'); 
      localStorage.removeItem('email'); 
      localStorage.removeItem('activeSection')
      localStorage.removeItem('avatarUrl')
      localStorage.removeItem('status')
      localStorage.removeItem('status2')
      localStorage.removeItem('update1')
      localStorage.removeItem('update2')
      localStorage.removeItem('username')
      localStorage.removeItem('verified')
      setIsLoggedIn(false); // Update state
      navigate('/'); // Redirect to home page
    };

  return (
    <nav className="navbar">
      <div className="nav-items">
        <div className="navbar-logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Logo" />
          </Link>
        </div>
        <div className="navbar-links">
          <Link to="/">Home</Link>
          {/* <Link to="#">Feature</Link>
          <Link to="#">Pricing</Link> */}
          <Link to="/#feature">Feature</Link>
          <Link to="/#pricing">Pricing</Link>
          <Link to="/dashboard">User Portal</Link>
          <Link to="/all-blogs">Blog</Link>
        </div>
        <div className="navbar-buttons">
          {isLoggedIn ? (
            <button onClick={handleLogout} className="btn active-btn">Logout</button>
          ) : (
            <>
              <Link to="/login" className="btn">Login</Link>
              <Link to="/register" className="btn active-btn">Sign up</Link>
            </>
          )}
        </div>
        <div className="menu-icon" id="menu-icon">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      <div className="mobile-menu" id="mobile-menu">
        <div className="mobile-links">
          <Link to="/">Home</Link>
          <Link to="/#feature">Feature</Link>
          <Link to="/#pricing">Pricing</Link>
          <Link to="/dashboard">User Portal</Link>
          <Link to="/all-blogs">Blog</Link>
        </div>
        <div className="mobile-buttons">
          {isLoggedIn ? (
            <button onClick={handleLogout} className="btn active-btn">Logout</button>
          ) : (
            <>
              <Link to="/login" className="btn">Login</Link>
              <Link to="/register" className="btn active-btn">Signup</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

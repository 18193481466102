import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ActivateAccount = () => {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('Activating your account...');

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axios.post(`${config.apiBaseUrl}/activate/`, {
                    uid,
                    token
                });
                setMessage(response.data.message);
                toast.success(message);
                setTimeout(() => navigate('/login'), 5000);  // Redirect to login after successful activation
            } catch (error) {
                setMessage('Activation failed. Invalid or expired link.');
                // toast.error(message)
                setTimeout(() => navigate('/login'), 5000);
            }
        };

        activateAccount();
    }, [uid, token, navigate]);

    return (
        <div className='' style={{minHeight:'300px', marginTop:'0px', display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
            <h1>Account Activation</h1> 
            <p>{message}</p>
            
        </div>
    );
};

export default ActivateAccount;

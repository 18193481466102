import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams<{ uid: string; token: string }>();
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiBaseUrl}/reset-password/`, { uid, token, new_password1: newPassword1, new_password2: newPassword2 });
      setMessage(response.data.message);
      navigate('/verified')
    } catch (error) {
      console.error('Failed to reset password', error);
      setMessage('Failed to reset password');
    }
  };

  return (
    <div className="signup-container">
    <div className="signup-main-modal">
        <div className="signup-modal">
            <img src="/images2/Accu-logo.png" alt="Accuprice Logo" />
            <h3>Reset Password</h3>
            {message && <p>{message}</p>}
            <form className='register-form' onSubmit={handleSubmit} >
               <input type="password" placeholder='New Password' value={newPassword1} onChange={(e) => setNewPassword1(e.target.value)} required />
               <input type="password" placeholder='Re-enter New Password' value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} required />
               <button type="submit">Reset Password</button>
            </form>
        </div>
    </div>
</div>



    // <div>
    //   <h1>Reset Password</h1>
    //   <form onSubmit={handleSubmit}>
    //     <label>New Password:</label>
    //     <input
    //       type="password"
    //       value={newPassword1}
    //       onChange={(e) => setNewPassword1(e.target.value)}
    //       required
    //     />
    //     <label>Confirm New Password:</label>
    //     <input
    //       type="password"
    //       value={newPassword2}
    //       onChange={(e) => setNewPassword2(e.target.value)}
    //       required
    //     />
    //     <button type="submit">Reset Password</button>
    //   </form>
    //   {message && <p>{message}</p>}
    // </div>
  );
};

export default ResetPassword;

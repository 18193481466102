const config = {
  // apiBaseUrl: 'http://127.0.0.1:8000/api',
  // imageBaseUrl: 'http://127.0.0.1:8000',
  // apiBaseUrl: 'http://34.206.146.16:8000',
  //    apiBaseUrl: 'http://34.206.42.215',
  apiBaseUrl: 'https://accupricelists.com/api',
  imageBaseUrl: 'https://accupricelists.com',

  
  
  };
  
  export default config;
import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiBaseUrl}/request-password-reset/`, { email });
      setMessage(response.data.message);
      toast.success(response.data.message);
      // alert('link sent')
    } catch (error) {
      console.error('Failed to send reset email', error);
      setMessage('Failed to send reset email');
      toast.error('Failed to send reset email');
    }
  };

  return (
    <div className="signup-container">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
    <div className="signup-main-modal">
        <div className="signup-modal">
            <img src="/images2/Accu-logo.png" alt="Accuprice Logo" />
            <h3>Forgot Password</h3>
            {message && <p>{message}</p>}
            <form className='register-form' onSubmit={handleSubmit}>
            <input type="email" value={email} placeholder='Enter your email here..' onChange={(e) => setEmail(e.target.value)} required />
                <button type="submit">Send Reset Link</button>
            </form>
        </div>
    </div>
</div>
  );
};

export default ForgotPassword;

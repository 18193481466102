import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from './Header';
import { UserProvider } from '../Usercontext'; 

const DashboardLayout: React.FC = () => {
  return (
    <UserProvider>
      <div className="container">
        <Sidebar />
        <div className="main-content">
          <DashboardHeader />
          <main>
            <Outlet /> {/* Nested routes will render here */}
          </main>
        </div>/
      </div>
    </UserProvider>
  );
};

export default DashboardLayout;

import React,{ useState }  from 'react';
import CustomModal from './modal';

import axios, { AxiosError } from 'axios';
import config from "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Footer: React.FC = () => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  
  const handlenewsSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(`${config.apiBaseUrl}/newsletter_email/`, { email });
      if (response.data.success) {
        toast.success('News letter status updated.');
      } else {
        setMessage(response.data.message || 'Something went wrong.');
      }
    } catch (error: any) {
      // Check if the error response exists from the backend
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || 'An unknown error occurred.';
        toast.error(`${errorMessage}`);
      } else {
        toast.error('An error occurred while sending the request.');
      }
    }
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
    <footer className="footer-section">
      <div className="footer-top">
        <div className="footer-logo-social">
          <div className="footer-logo">
            <img src="/images/logo.png" alt="AccuPrice Logo" />
          </div>
          <div className="footer-social">
            <a href="https://facebook.com/" aria-label="Facebook"><i className="fab fa-facebook"></i></a>
            <a href="https://linkedin.com/" aria-label="LinkedIn"><i className="fab fa-linkedin"></i></a>
            <a href="https://twitter.com/" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
            <a href="https://instagram.com/" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
        <div className="footer-newsletter">
          <h2>Join our newsletter</h2>
          <p className="stay-uptodate">Stay up-to-date with our new blog posts by subscribing here</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" required  value={email} onChange={(e) => setEmail(e.target.value)}/>
            <button onClick={handlenewsSubmit} type="submit">Subscribe</button>
          </form>
          <p className="privacy-policy">
            By subscribing you agree to with our <a href="#">Privacy Policy</a>.
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-links">
        <a href="#" onClick={() => setShowPrivacyModal(true)}>Privacy Policy</a>
          <a href="#" onClick={() => setShowTermsModal(true)}>Terms of Service</a>
          <a href="#" onClick={() => setShowCookiesModal(true)}>Cookies Settings</a>
        </div>
        <p className="footer-copyright">
          © 2024 All rights reserved.
        </p>
      </div>
      {/* Privacy Policy Modal */}
      <CustomModal
        isOpen={showPrivacyModal}
        onClose={() => setShowPrivacyModal(false)}
        title="Privacy Policy"
      >
        <p>Your privacy policy content...</p>
      </CustomModal>

      {/* Terms of Service Modal */}
      <CustomModal
        isOpen={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        title="Terms of Service"
      >
        <p>Your terms of service content...</p>
      </CustomModal>

      {/* Cookies Settings Modal */}
      <CustomModal
        isOpen={showCookiesModal}
        onClose={() => setShowCookiesModal(false)}
        title="Cookies Settings"
      >
        <p>Your cookies settings content...</p>
      </CustomModal>
    </footer>


   
    </>);
};

export default Footer;
